<template>
  <v-row style="padding-bottom: 50px;">
    <v-col>
      <v-sheet
        class="py-5"
        max-height="140"
        rounded="xl"
        :color="color"
      >
        <v-row style="overflow: visible">
          <v-col cols="9">
            <h2 class="mx-5 text-truncate" style="color: white">{{ title }}</h2>
          </v-col>
          <v-col cols="3">
            <img
              :class="{microscopio: isExam, 'edit-medical-mask-picture': !isExam}"
              v-if="titleIcon"
              :src="titleIcon"
              :style="cssVars"
            />
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="px-3">
          <template v-for="(item, index) in totals">
            <v-col :key="index" cols="4" class="d-flex justify-center">
              <v-sheet
                class="py-4 items-sheet px-xs-0 px-sm-5 px-md-10"
                elevation="10"
                max-width="180"
                min-width="100"
                :height="height"
                style="text-align: center"
              >
                <span class="totalizator">{{item.value}}</span>
                <p class="text-caption totalizator-description" v-html="item.label"/>
              </v-sheet>
            </v-col>
          </template>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "index",
  props:{
    title: {
      type: String,
      required: true
    },
    titleIcon: {
      required: false
    },
    iconStyle:{
      type: Object,
      required: false,
      default: null
    },
    bgColor: {
      type:String,
      required: false,
      default: '#6CAFE0'
    },
    totals: {
      type: Array,
      required: true
    },
    color: {
      default: '#6CAFE0',
      type: String
    },
    height: {
      default: 90,
      type: Number
    },
    isExam: {
      type: Boolean,
      default: false,
    },
    tileIconHeight: {
      default: 99,
      type: Number
    },
    tileIconWidth: {
      default: 148,
      type: Number
    },
    tileIconTop: {
      default: 65,
      type: Number
    },
    tileIconRight: {
      default: -5,
      type: Number
    },
  },
  computed: {
    cssVars() {
      return {
        '--icon-right': `${this.tileIconRight}px`,
        '--icon-top': `${this.tileIconTop}px`,
        '--icon-width': `${this.tileIconWidth}px`,
        '--icon-height': `${this.tileIconHeight}px`,
      }
    }
  }
}
</script>

<style scoped>

.totalizator {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #292867;
}

.totalizator-description{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: #292867;
}

@media (max-width: 415px) {
  .items-sheet{ width: 100px; }
}

@media (min-width: 500px) {
  .items-sheet{ width: 130px; }
}

@media (max-width: 550px) {
  .items-sheet{ width: 150px; }
}

@media (min-width: 600px) {
  .items-sheet{ width: 180px; }
}

.items-sheet{
  border-radius: 14px;
}

.edit-medical-mask-picture{
  position: absolute;
  width: var(--icon-width) !important;
  height: var(--icon-height) !important;
  top: var(--icon-top) !important;
  right: var(--icon-right) !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.microscopio{
  position: absolute;
  width: 90px;
  height: 135px;
  left: 280px !important;
  top: 50px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;

}

@media (min-width: 320px) {
  .edit-medical-mask-picture{
    left: 160px !important;
  }
  .microscopio{
    left: 190px !important;
  }
}

@media (min-width: 360px) {
  .edit-medical-mask-picture{
    left: 230px !important;
  }
  .microscopio{
    left: 235px !important;
  }
}

@media (min-width: 400px) {
  .edit-medical-mask-picture{
    left: 240px !important;
  }
  .microscopio{
    left: 265px !important;
  }
}
</style>