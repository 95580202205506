<template>
  <v-container style="height: 100%" class="bg-glass-effect-lighter px-3 pt-15" fluid>
    <Back :route="'root'" v-if="isLoading"/>
    <Totalizer
      class="mb-2"
      style="font-size: 14px"
      :totals="totals"
      :title="title"
      :title-icon="titleIcon"
      :height="80"
      :tile-icon-right="90"
      :tile-icon-top="10"
      :tile-icon-width="175"
      :tile-icon-height="150"
      v-if="isLoading"
    />
    <v-row class="mt-3" v-if="isLoading && this.$auth.user().hasCompanyConnection">
      <v-col>
        <v-card elevation="5" rounded="xl" :color="card.color" style="overflow: visible;">
          <v-row style="overflow: visible;">
            <v-col cols="3" content="center" style="overflow: visible;">
              <v-row justify="center">
                <v-col cols="8">
                  <v-img class="nick-exclamation" src="/img/nick/nick-exclamation.svg"></v-img>
                </v-col>
              </v-row>
            </v-col>
            <v-col content="center" cols="9">
              <p class="pl-3 pr-2 pb-0 mb-0" style="font-size: 14px; color: #292867;">{{card.message}}</p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="py-1" v-for="(menu,index) in menus" :key="index" v-if="isLoading">
      <v-col v-if="menu.subMenus && menu.subMenus.length > 0" class="px-7" cols="12">
        <h2 style="color: #292867;">{{menu.title}}</h2>
      </v-col>
      <v-col cols="12">
        <v-slide-group >
          <v-slide-item
            v-for="(sub, index) in menu.subMenus"
            :key="index"
          >
            <v-card
              elevation="8"
              class="mx-2 my-5 item-card"
              width="180"
              height="190"
            >
              <v-card-text class="mt-1" @click="clickCard(sub)">
                <!--reverse condition: card mt-0 / v-row align="center"-->
                <v-row style="height: 180px">
                  <v-col cols="12">
                    <v-row align="center" class="pt-7" justify="center">

                      <v-badge v-if="sub.badge"  color="transparent" offset-x="30" offset-y="15">
                        <template v-slot:badge>
                          <v-avatar size="30">
                            <v-img :src="sub.badge"></v-img>
                          </v-avatar>
                        </template>
                        <template>
                          <v-img max-height="80" max-width="80" :src="require('../../../public/img/icons/menu-covid/' + sub.icon + '')"></v-img>
                        </template>
                      </v-badge>
                      <v-img v-else max-height="80" max-width="80" :src="require('../../../public/img/icons/menu-covid/' + sub.icon + '')"></v-img>

                    </v-row>
                    <v-row class="py-1" align="center" justify="center">
                      <p class="description-menu">{{sub.title}}</p>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Totalizer from '@/components/generic-totalizer'
import Back from '@/components/covid/back-arrow'
import EuProtegidoService from "@/services/euprotegido/EuProtegidoService";
import {mapMutations} from "vuex";
import MenuService from "@/services/security/Menu";
export default {
  name: "miniMenuCovid",
  components: {
    Back,
    Totalizer,
  },
  data: () => ({
    menus: [],
    totals: [
      {
        label: "Triagens <br/> realizadas",
        value: 0
      },
      {
        label: "Testes <br/> realizados",
        value: 0
      },
      {
        label: "Dose da vacina agendadas",
        value: 0
      },
    ],
    title: "Protocolo COVID-19",
    titleIcon: require('../../../public/img/icons/menu-covid/icon-medical-mask-menu-covid.svg'),
    card: {
      color: '',
      colorDone: '#E9EBFD',
      colorNotDone: '#FADD8F',
      message: '',
      messageDone: 'Você está em dia com a Trilha de Conhecimento. Realize a Triagem.',
      messageNotDone: 'Novos procedimentos contra a COVID-19. Responda novamente a Trilha de Conhecimento.'
    },
    isTrailDone: true,
    isLoading: false,
  }),
  async created () {
    this._menuService = new MenuService();
    this._euProtegidoService = new EuProtegidoService();
    this.loading(true)

    await this.loadTotalizers()
    await this.reloadMenus()
  },
  methods:{
    ...mapMutations(["loading"]),
    clickCard(menu){
      if(menu.badge && menu.badge.includes('lock')){
        return;
      }

      switch (menu.type){
        case 'EXTERNAL_URL':
          window.location.href = menu.link
          break;
        default:
          this.routerByMenu(menu);
      }
    },
    routerByMenu(menu){
      let queryParamters;
      try {
        queryParamters = JSON.parse(menu.options);
      }catch (error){
        // vazio
      }
      this.$router.push({
        name: menu.link,
        query: queryParamters
      });
    },
    verifyTrailDone(){
      for(let i=0; i < this.menus.length; i++) {
        if(this.menus[i].subMenus){
          for(let j=0; j < this.menus[i].subMenus.length; j++){
            if(this.menus[i].subMenus[j].title == 'Trilha de conhecimento'){
              if(this.menus[i].subMenus[j].badge){
                const trailBadge = this.menus[i].subMenus[j].badge
                if(trailBadge && trailBadge.trim().length > 0) {
                  this.isTrailDone = true
                  this.card.color = this.card.colorDone
                  this.card.message =this.card.messageDone
                }else{
                  this.isTrailDone = false
                  this.card.color = this.card.colorNotDone
                  this.card.message = this.card.messageNotDone
                }

              }else{
                this.isTrailDone = false
                this.card.color = this.card.colorNotDone
                this.card.message = this.card.messageNotDone
              }
              break;
            }
          }
        }
      }
    },
    async loadTotalizers(){
      await this._euProtegidoService.GetCovidTotalizers()
          .then((response) => {
            if(response.data){
              this.totals[0].value = response.data.totalScreeningsRealized
              this.totals[1].value = response.data.totalExamsPcrRealized
              this.totals[2].value = response.data.totalVaccinesCovidRealized
            }
          })
          .catch((error) => {
          })
    },
    loadMenuNick(){
      this.menus = this.$auth.getMenuNick().filter(value => {
        return value.link == 'miniHomeCovid'
      })[0].subMenus

      this.card.color = this.card.colorDone
      this.card.message = this.card.messageDone

      this.verifyTrailDone()
    },
    reloadMenus(){
      this._menuService.LoadMenu().
          then((response) => {
            if(response.data){
              let session = this.$auth.getSession()
              session.menu = response.data
              this.$auth.setSession(session)
            }
          })
          .finally(() => {
              this.loadMenuNick()
              this.isLoading = true
              this.loading(false)
          });
    }
  }
}
</script>

<style scoped>

/*background effect blur*/

.item-card{
  border-radius: 14px;
}

.description-menu {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #292867;
}

.nick-exclamation {
  position: absolute !important;
  width: 71px !important;
  height: 72px !important;
  top: -15px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
</style>


